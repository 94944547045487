<template>
    <div class="alert alert-warning mb-4 has-error" aria-atomic="true" role="alert" v-if="modelState !== null && Object.keys(modelState).length > 0 && modelState[GenericValidationError] !== undefined">
        <p class="mb-0">
            <icon-exclamation-triangle></icon-exclamation-triangle>
            <span class="validation-error-message">{{ modelState[GenericValidationError] }}</span>
        </p>
    </div>
</template>

<script>
    import { BIcon, BIconExclamationTriangle } from 'bootstrap-vue'


    export default {
        components: {
            "icon-exclamation-triangle": BIconExclamationTriangle
        },
        props: {
            modelState: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                GenericValidationError: "GenericValidationError"
            }
        },
        mounted() {
        },
        methods: {
        }
    }

</script>


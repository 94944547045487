//always import this in each page specific file start
import Vue from 'vue';
//always import this in each page specific file end
import UserService from '../services/user.service';

import { BOverlay, BIcon, BIconCheckCircle, BIconInfoCircle, BIconExclamationTriangle } from 'bootstrap-vue'
import InputModelStateValidationComponent from '../components/shared/InputModelStateValidation.vue';
import ModelStateGenericValidationErrorComponent from '../components/shared/ModelStateGenericValidationError.vue';
import DisplayError from '../components/shared/DisplayError.vue';
import TemporaryMessage from "../components/shared/TemporaryMessage.vue";

import VueMask from 'v-mask'
Vue.use(VueMask);

import axios from 'axios';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';


//Shared entry for all pages that are not logged in RegisterUser, Login, ForgotPassword
new Vue({
    el: "#app",
    data() {
        return {
            isLoading: false,
            loginInput: {},
            registerInput: {},
            forgotPasswordInput: {},
            recoverPasswordInput: {},
            modelState: {},
            errorMessage: null,
            isSuccess: false,
            expiredPasswordUserIdSessionStorageKey: "expiredpassword",
            showCookieBox: true
        }
    },
    components: {
        'vue-input-model-state-validation-component': InputModelStateValidationComponent,
        'vue-generic-validation-error': ModelStateGenericValidationErrorComponent,
        "vue-display-error": DisplayError,
        "b-overlay": BOverlay,
        BIcon,
        BIconCheckCircle,
        BIconInfoCircle,
        BIconExclamationTriangle,
        temporarymessage: TemporaryMessage
    },
    mounted() {

    },
    methods: {
        consentCookies() {
            this.showCookieBox = false;
        },
        login() {
            let self = this;

            if (self.isLoading) {
                return false;
            }

            self.isLoading = true;
            self.errorMessage = null;
            self.modelState = {};

            var returnUrl = document.getElementById("hidReturnUrl").value;

            self.loginInput.ReturnUrl = returnUrl;

            UserService.login(self.loginInput).then(
                response => {

                    var federatedLoginResponse = response.data;

                    if (federatedLoginResponse.IsSuccess) {
                        window.location.href = federatedLoginResponse.Data.RedirectUrl;
                    } else {
                        self.isLoading = false;

                        self.errorMessage = federatedLoginResponse.ErrorMessage;
                    }
                },
                error => {

                    if (error.response.status === 400) {
                        self.modelState = JSON.parse(error.response.data);

                        //scroll to error
                        this.$nextTick(() => {
                            let firstErrorElement = document.getElementsByClassName("has-error")[0];

                            if (firstErrorElement !== undefined) {
                                firstErrorElement.scrollIntoView();
                            }
                        });
                    } else {
                        window.scrollTo(0, 0);
                        this.errorMessage = error.response.data;
                    }

                    self.isLoading = false;
                }
            );
        },
        loginDev() {
            let self = this;

            if (self.isLoading) {
                return false;
            }

            self.isLoading = true;
            self.errorMessage = null;
            self.modelState = {};

            var returnUrl = document.getElementById("hidReturnUrl").value;

            self.loginInput.ReturnUrl = returnUrl;

            UserService.loginDev(self.loginInput).then(
                response => {

                    var response = response.data;
                    window.location.href = response.RedirectUrl;

                },
                error => {

                    if (error.response.status === 400) {
                        self.modelState = JSON.parse(error.response.data);

                        //scroll to error
                        this.$nextTick(() => {
                            let firstErrorElement = document.getElementsByClassName("has-error")[0];

                            if (firstErrorElement !== undefined) {
                                firstErrorElement.scrollIntoView();
                            }
                        });
                    } else {
                        window.scrollTo(0, 0);
                        this.errorMessage = error.response.data;
                    }

                    self.isLoading = false;
                }
            );
        },
        createUser() {
            let self = this;

            if (self.isLoading) {
                return false;
            }

            self.isLoading = true;

            self.modelState = {};
            this.errorMessage = null;

            UserService.createAccount(self.registerInput).then(
                response => {
                    var federatedLoginResponse = response.data;

                    if (federatedLoginResponse.IsSuccess) {
                        window.location.href = federatedLoginResponse.Data.RedirectUrl;
                    } else {
                        self.isLoading = false;

                        self.errorMessage = federatedLoginResponse.ErrorMessage;
                    }
                },
                error => {

                    if (error.response.status === 400) {
                        self.modelState = JSON.parse(error.response.data);

                        //scroll to error
                        this.$nextTick(() => {
                            let firstErrorElement = document.getElementsByClassName("has-error")[0];

                            if (firstErrorElement !== undefined) {
                                firstErrorElement.scrollIntoView();
                            }
                        });
                    } else {
                        window.scrollTo(0, 0);
                        this.errorMessage = error.response.data;
                    }

                    self.isLoading = false;
                }
            );

        },
        submitForgotPassword() {
            let self = this;

            if (self.isLoading) {
                return false;
            }

            self.isLoading = true;

            self.modelState = {};
            this.errorMessage = null;

            UserService.forgotPassword(self.forgotPasswordInput).then(
                response => {
                    window.location.href = response.data.RedirectUrl;
                },
                error => {

                    if (error.response.status === 400) {
                        self.modelState = JSON.parse(error.response.data);

                        //scroll to error
                        this.$nextTick(() => {
                            let firstErrorElement = document.getElementsByClassName("has-error")[0];

                            if (firstErrorElement !== undefined) {
                                firstErrorElement.scrollIntoView();
                            }
                        });
                    } else {
                        window.scrollTo(0, 0);
                        this.errorMessage = error.response.data;
                    }

                    self.isLoading = false;
                }
            );
        },
        submitRecoverPassword() {
            let self = this;

            if (self.isLoading) {
                return false;
            }

            self.isLoading = true;

            self.modelState = {};
            this.errorMessage = null;

            var passwordEncryptedParameter = document.getElementById("hidPasswordEncryptedParameter").value;

            if (self.recoverPasswordInput) {
                self.recoverPasswordInput.RecoverPasswordEncryptedParameter = passwordEncryptedParameter;
            }

            UserService.recoverPassword(self.recoverPasswordInput).then(
                response => {
                    if (response) {
                        self.isSuccess = true;
                    }
                    self.recoverPasswordInput = {};
                    self.isLoading = false;
                },
                error => {

                    if (error.response.status === 400) {
                        self.modelState = JSON.parse(error.response.data);

                        //scroll to error
                        this.$nextTick(() => {
                            let firstErrorElement = document.getElementsByClassName("has-error")[0];

                            if (firstErrorElement !== undefined) {
                                firstErrorElement.scrollIntoView();
                            }
                        });
                    } else {
                        window.scrollTo(0, 0);
                        this.errorMessage = error.response.data;
                    }

                    self.isLoading = false;
                }
            );
        }
    }
});
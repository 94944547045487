import axios from 'axios';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const MY_ACCOUNT_API_URL = '/Account/MyAccount/';
const SELECT_TYPE_OF_APPLICANT_API_URL = "/Account/SelectTypeOfApplicant/";

class UserService {
    getAllUsers() {
        return axios.get("/GetAllUsers");
    }

    login(user) {
        var __RequestVerificationToken = document.querySelector('input[name="__RequestVerificationToken"]').value;

        return axios.post("/Account/Login",
            user,
            {
                headers: {
                    'RequestVerificationToken': __RequestVerificationToken
                }
            });
    }
    loginDev(user) {
        var __RequestVerificationToken = document.querySelector('input[name="__RequestVerificationToken"]').value;

        return axios.post("/Account/Login/LoginDev",
            user,
            {
                headers: {
                    'RequestVerificationToken': __RequestVerificationToken
                }
            });
    }
    createAccount(inputModel) {
        var __RequestVerificationToken = document.querySelector('input[name="__RequestVerificationToken"]').value;

        return axios.post("/Account/Register",
            inputModel,
            {
                headers: {
                    'RequestVerificationToken': __RequestVerificationToken
                }
            });
    }

    getUserInfo() {
        return axios.get(`${MY_ACCOUNT_API_URL}UserInfo`);
    }
    
    getUserApplicantInfo() {
        return axios.get(`${SELECT_TYPE_OF_APPLICANT_API_URL}UserApplicantInfo`);

    }

    validateCreatePrivateAccountStep1(inputModel) {
        var __RequestVerificationToken = document.querySelector('input[name="__RequestVerificationToken"]').value;

        return axios.post(`${SELECT_TYPE_OF_APPLICANT_API_URL}ValidateCreatePrivateAccountStep1`,
            inputModel,
            {
                headers: {
                    'RequestVerificationToken': __RequestVerificationToken
                }
            });
    }

    createPrivateAccount(inputModel) {
        var __RequestVerificationToken = document.querySelector('input[name="__RequestVerificationToken"]').value;

        return axios.post(`${SELECT_TYPE_OF_APPLICANT_API_URL}CreatePrivateAccount`,
            inputModel,
            {
                headers: {
                    'RequestVerificationToken': __RequestVerificationToken
                }
            });
    }


    validateCreateOrganisationAccountStep1(inputModel) {
        var __RequestVerificationToken = document.querySelector('input[name="__RequestVerificationToken"]').value;

        return axios.post(`${SELECT_TYPE_OF_APPLICANT_API_URL}ValidateCreateOrganisationAccountStep1`,
            inputModel,
            {
                headers: {
                    'RequestVerificationToken': __RequestVerificationToken
                }
            });
    }

    validateCreateOrganisationAccountStep2(inputModel) {
        var __RequestVerificationToken = document.querySelector('input[name="__RequestVerificationToken"]').value;

        return axios.post(`${SELECT_TYPE_OF_APPLICANT_API_URL}ValidateCreateOrganisationAccountStep2`,
            inputModel,
            {
                headers: {
                    'RequestVerificationToken': __RequestVerificationToken
                }
            });
    }

    createOrganisationAccount(inputModel) {
        var __RequestVerificationToken = document.querySelector('input[name="__RequestVerificationToken"]').value;

        return axios.post(`${SELECT_TYPE_OF_APPLICANT_API_URL}CreateOrganisationAccount`,
            inputModel,
            {
                headers: {
                    'RequestVerificationToken': __RequestVerificationToken
                }
            });
    }


    saveUserInfo(inputModel) {
        var __RequestVerificationToken = document.querySelector('input[name="__RequestVerificationToken"]').value;

        return axios.post(`${MY_ACCOUNT_API_URL}SaveUserInfo`,
            inputModel,
            {
                headers: {
                    'RequestVerificationToken': __RequestVerificationToken
                }
            });
    }


    deleteUser() {
        var __RequestVerificationToken = document.querySelector('input[name="__RequestVerificationToken"]').value;

        return axios.post(`${MY_ACCOUNT_API_URL}DeleteUser`,
            null,
            {
                headers: {
                    'RequestVerificationToken': __RequestVerificationToken
                }
            });
    }

    getPrivateUserInformation() {
        return axios.get(`/Account/PrivateAccountSettings/PrivateUserInformation`);
    }

    updatePrivateAccount(inputModel) {
        var __RequestVerificationToken = document.querySelector('input[name="__RequestVerificationToken"]').value;

        return axios.post(`/Account/PrivateAccountSettings/UpdatePrivateAccount`,
            inputModel,
            {
                headers: {
                    'RequestVerificationToken': __RequestVerificationToken
                }
            });
    }

    deletePrivateAccount() {
        var __RequestVerificationToken = document.querySelector('input[name="__RequestVerificationToken"]').value;

        return axios.post(`/Account/MyAccount/DeletePrivateAccount`,
            null,
            {
                headers: {
                    'RequestVerificationToken': __RequestVerificationToken
                }
            });
    }


}

export default new UserService();
<template>
    <div :class="{'has-error': modelState !== null && modelState[propertyName] !== undefined}">
        <slot></slot>
        <span class="form-error-message" v-if="modelState !== null && modelState[propertyName] !== undefined">
            <icon-exclamation-triangle></icon-exclamation-triangle>
            {{ modelState[propertyName] }}
        </span>
    </div>
</template>

<script>
    import { BIcon, BIconExclamationTriangle } from 'bootstrap-vue'

    export default {
        components: {
            "icon-exclamation-triangle": BIconExclamationTriangle
        },
        props: {
            propertyName: {
                type: String,
                default: null
            },
            modelState: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
            }
        },
        mounted() {
        },
        methods: {
        }
    }

</script>

<template>
    <div class="alert alert-danger" role="alert" aria-atomic="true" v-if="errorMessage != null">
        <div class="row">
            <div class="col-2 col-sm-1 alert-col">
                <div class="alert-icon">
                    <i class="icon icon-exclamation-mark circle-around"></i>
                </div>
            </div>
            <div class="col-10 col-sm-11">
                <p>
                    <icon-exclamation-triangle></icon-exclamation-triangle>

                    {{errorMessage}}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import { BIcon, BIconExclamationTriangle } from 'bootstrap-vue'
    //Vue.component('b-icon', BIcon)

    export default {
        components: {
            "icon-exclamation-triangle": BIconExclamationTriangle
        },
        props: {
            errorMessage: {
                type: String,
                default: null
            },
            customMessage: {
                type: String,
                default: null
            },
            failedObjectType: {
                type: String,
                default: 'resources'
            }
        },
        mounted() {
        },
        methods: {

        }
    }

</script>
<template>
  <div :class="cssClass" role="alert" aria-atomic="true" v-if="message != null">
    {{ message.Message }}
  </div>
</template>

<script>

import axios from "axios";

export default {
  components: {},
  props: {
    visibilityTypeEnumId: {
      type: Number,
      default: () => 0
    },
  },
  data: () => ({
    message: null,
    cssClass: "alert alert-danger",
    severityTypes: [
      {id: 1, Name: "Hög allvarlighet"},
      {id: 2, Name: "Varning"},
      {id: 3, Name: "Information"}],
    title: "",
  }),
  mounted() {
    let self = this;

    var params = {
      visibilityType: self.visibilityTypeEnumId,
      cacheKill: (new Date()).getTime(),
    }

    return axios.get(`/TemporaryMessage/GetTemporaryMessage`,
        {
          params
        }).then((response) => {
      if (response.status === 204) {
        return;
      }

      self.message = response.data;

      if (self.message != null && self.message !== "") {
        if (self.message.Severity == 1)
          self.cssClass = "alert alert-danger";
        if (self.message.Severity == 2)
          self.cssClass = "alert alert-warning";
        if (self.message.Severity == 3)
          self.cssClass = "alert alert-info";

        self.title = self.severityTypes.filter(x => x.id == self.message.Severity)[0].Name;
      }
    });
  },
  methods: {}
}

</script>